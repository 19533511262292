import type { BundleServiceKey } from '@/shared/types/BundleServiceKey'

export const MEXICO_CITY_AREA_CODE = '55'

export const LOCAL_STORAGE_SKIP_HOME_KEY = 'padnet:skip_home'

export const LOCAL_STORAGE_SKIP_PACKAGES_KEY = 'padnet:skip_packages'

export const LOCAL_STORAGE_ADS_SUBSCRIPTIONS_KEY = 'padnet:ads_subscriptions'

export const AMAZON_BUNDLE_SERVICES_KEYS: BundleServiceKey[] = ['[AP]', '[APB]', '[APVM]']

export const PREPAID_PACKAGE_PA2400_EXTERNAL_ID = 'PA2400'

export const PREPAID_PACKAGE_SR1200_EXTERNAL_ID = 'SR1200'

export const PROMOTIONAL_PACKAGES = [
  PREPAID_PACKAGE_PA2400_EXTERNAL_ID,
  PREPAID_PACKAGE_SR1200_EXTERNAL_ID
]
