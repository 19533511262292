export const NULL_CAMPAIGN = '_NULL'

export const APP_WEBVIEW_CAMPAIGN = '006'

export const PKCONTILIM_CAMPAIGN = 'PKCONTILIM'

export const MTS_CAMPAIGN = 'MTS'

export const DIRECT_PACKAGE_CAMPAIGNS = ['2HRSFACE', '2HRSINSTA', '2HRSX', '2HRSY']

export const PACKAGES_CAMPAIGNS = [
  '010',
  '011',
  '012',
  '013',
  'IMOX',
  'MDPLUS',
  'MIMEDIA',
  'FACE',
  'INSTA',
  'XES',
  'CAPP',
  'CAPPSL',
  'CAPPINT',
  'CAPPREC',
  'MDR1',
  'MDR2'
]

export const SEND_BALANCE_CAMPAIGN = 'REC03'

export const REQUEST_BALANCE_CAMPAIGN = 'SOLICITARSALDO'

export const BOTONSALDO_CAMPAIGN = 'BOTONSALDO'

export const CSALDO_CAMPAIGN = 'CSALDO'

export const CSLD_CAMPAIGN = 'CSLD'

export const PREVIEW_PACKAGE_CAMPAIGN_PREFIXES = [
  'PK',
  'PQ',
  'M1',
  'M1',
  'M2',
  'M2',
  'M2',
  'M2',
  'M3',
  'M3',
  'M3',
  'M3',
  'M3',
  'M4',
  'M4',
  'M4',
  'M4',
  'M4',
  'S1',
  'S1',
  'S1',
  'S2',
  'S2',
  'S2',
  'S3',
  'S3',
  'S3',
  'S4',
  'S4',
  'S4',
  'S5',
  'S5',
  'S5',
  'S6',
  'S6',
  'S6',
  'S7',
  'S7',
  'S8',
  'S8',
  'S8',
  'S9',
  'S9',
  'S9',
  'SA',
  'SA',
  'SA',
  'SB',
  'SB',
  'SB',
  'SC',
  'SC',
  'SC',
  'I1',
  'I1',
  'I1',
  'I1',
  'I2',
  'I2',
  'I2',
  'I2',
  'I3',
  'I3',
  'I3',
  'I3',
  'I4',
  'I4',
  'I4',
  'I4',
  'I5',
  'I5',
  'I5',
  'I5',
  'I6',
  'I6',
  'I6',
  'I6',
  'I7',
  'I7',
  'I7',
  'I7',
  'I8',
  'I8',
  'I8',
  'I8',
  'I9',
  'I9',
  'I9',
  'I9',
  'IA',
  'IA',
  'IA',
  'IA',
  'P1',
  'P1',
  'P1',
  'P1',
  'P2',
  'P2',
  'P2',
  'P2',
  'P2',
  'N1',
  'N2'
]

export const PAYMENT_CAMPAIGN_PREFIXES = [
  'CR',
  'D1',
  'D1',
  'D2',
  'D2',
  'D2',
  'D2',
  'D3',
  'D3',
  'D3',
  'D3',
  'D3',
  'D4',
  'D4',
  'D4',
  'D4',
  'D4',
  'L1',
  'L1',
  'L1',
  'L2',
  'L2',
  'L2',
  'L3',
  'L3',
  'L3',
  'L4',
  'L4',
  'L4',
  'L5',
  'L5',
  'L5',
  'L6',
  'L6',
  'L6',
  'L7',
  'L7',
  'L8',
  'L8',
  'L8',
  'L9',
  'L9',
  'L9',
  'LA',
  'LA',
  'LA',
  'LB',
  'LB',
  'LB',
  'LC',
  'LC',
  'LC',
  'T1',
  'T1',
  'T1',
  'T1',
  'T2',
  'T2',
  'T2',
  'T2',
  'T3',
  'T3',
  'T3',
  'T3',
  'T4',
  'T4',
  'T4',
  'T4',
  'T5',
  'T5',
  'T5',
  'T5',
  'T6',
  'T6',
  'T6',
  'T6',
  'T7',
  'T7',
  'T7',
  'T7',
  'T8',
  'T8',
  'T8',
  'T8',
  'T9',
  'T9',
  'T9',
  'T9',
  'TA',
  'TA',
  'TA',
  'TA',
  'P3',
  'P3',
  'P3',
  'P3',
  'P4',
  'P4',
  'P4',
  'P4',
  'P4',
  'N3',
  'N4'
]

export const BALANCE_CAMPAIGNS = [BOTONSALDO_CAMPAIGN, CSALDO_CAMPAIGN, CSLD_CAMPAIGN]
