import { getAdsConfig } from '@/api/ads'
import { LOCAL_STORAGE_ADS_SUBSCRIPTIONS_KEY } from '@/shared/constants/general'
import useCheckoutStore from '@/stores/checkout'
import useTokenStore from '@/stores/token'
import AdType from '../enums/AdType'

export const addAdsSubscriptionToLocalStorage = (msisdn: string) => {
  const adsSubscriptions = getAdsSubscriptionsFromLocalStorage()
  const newAdsSubscriptions = JSON.stringify([...adsSubscriptions, msisdn])

  localStorage.setItem(LOCAL_STORAGE_ADS_SUBSCRIPTIONS_KEY, newAdsSubscriptions)
}

export const getAdsSubscriptionsFromLocalStorage = () => {
  try {
    const adsSubscriptions = localStorage.getItem(LOCAL_STORAGE_ADS_SUBSCRIPTIONS_KEY) || '[]'

    return JSON.parse(adsSubscriptions)
  } catch (error) {
    return {}
  }
}

export const fetchAdsSubscription = async () => {
  const tokenStore = useTokenStore()
  const checkoutStore = useCheckoutStore()

  try {
    const response = await getAdsConfig()

    if (response.data.type !== AdType.GRAY_LIST) {
      addAdsSubscriptionToLocalStorage(tokenStore.msisdn)

      checkoutStore.setAdsSubscription(true)
    } else {
      checkoutStore.setAdsSubscription(false)
    }
  } catch (error) {
    // ERROR HANDLER
  }
}
