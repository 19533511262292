import { fetchAdsSubscription, getAdsSubscriptionsFromLocalStorage } from '@/shared/utils/ads'
import useCheckoutStore from '@/stores/checkout'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'

const adsSubscriptionGuard: NavigationGuard = (to, from, next) => {
  const tokenStore = useTokenStore()
  const checkoutStore = useCheckoutStore()

  if (!tokenStore.msisdn || checkoutStore.adsSubscription !== null) {
    next()
    return
  }

  const adsSubscriptions = getAdsSubscriptionsFromLocalStorage()
  const subscribed = adsSubscriptions.includes(tokenStore.msisdn)

  if (subscribed) {
    checkoutStore.setAdsSubscription(true)
  } else {
    fetchAdsSubscription()
  }

  next()
}

export default adsSubscriptionGuard
