import type { AdsResponse } from '@/shared/interfaces/AdsResponse'
import type { UpdateAdsConfigBody } from '@/shared/interfaces/UpdateAdsConfigBody'
import request from '@/shared/utils/request'
import type { AxiosPromise } from 'axios'

export const getAdsConfig = (): AxiosPromise<AdsResponse> => {
  return request(
    {
      url: '/eiko-profile/telcel/ads',
      method: 'GET'
    },
    false
  )
}

export const updateAdsConfig = (data: UpdateAdsConfigBody): AxiosPromise<AdsResponse> => {
  return request(
    {
      url: '/eiko-profile/telcel/ads',
      method: 'POST',
      data
    },
    false
  )
}
