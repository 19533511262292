import CheckoutStep from '@/shared/enums/CheckoutStep'
import type { Package } from '@/shared/interfaces/Package'
import { defineStore } from 'pinia'
import useOfferStore from '@/stores/offer'
import PaymentMethod from '@/shared/enums/PaymentMethod'
import { switchOrder } from '@/api/orders'
import type { SwitchOrderPayload } from '@/shared/interfaces/SwitchOrderPayload'
import { ref, type Ref } from 'vue'

const DEFAULT_VALUES = {
  step: CheckoutStep.PREVIEW,
  folio: '',
  balance: 0,
  package: null,
  paymentMethod: PaymentMethod.CreditCard
}

const useCheckoutStore = defineStore('checkout', () => {
  const step = ref(CheckoutStep.PREVIEW)
  const folio = ref('')
  const errorFolio = ref('')
  const balance = ref(0)
  const pack: Ref<null | Package> = ref(null)
  const paymentMethod = ref(PaymentMethod.CreditCard)
  const adsSubscription = ref<null | boolean>(null)

  const getPackageByID = (id: string) => {
    const offerStore = useOfferStore()

    return offerStore.packages.find((p: Package) => p.id === id) || null
  }

  const getPackageByExternalID = (externalID: string) => {
    const offerStore = useOfferStore()

    return offerStore.packages.find((p: Package) => p.externalID === externalID) || null
  }

  const setStep = (value: CheckoutStep) => {
    step.value = value
  }

  const setFolio = (value: string) => {
    folio.value = value
  }

  const setErrorFolio = (value: string) => {
    errorFolio.value = value
  }

  const setPaymentMethod = (value: PaymentMethod) => {
    paymentMethod.value = value
  }

  const setPackage = (value: null | Package) => {
    pack.value = value
  }

  const setAdsSubscription = (value: null | boolean) => {
    adsSubscription.value = value
  }

  const setPackageById = (id: string) => {
    const offerPackage = getPackageByID(id)

    if (offerPackage) {
      setPackage(offerPackage)
    }
  }

  const setPackageByExternalID = (externalID: string) => {
    const offerPackage = getPackageByExternalID(externalID)

    if (offerPackage) {
      setPackage(offerPackage)
    }
  }

  const fetchSwitchOrder = async (data: SwitchOrderPayload) => {
    try {
      const response = await switchOrder(data)

      folio.value = response.data.folio
      balance.value = parseFloat(response.data.currentBalance || '')
      paymentMethod.value = PaymentMethod[response.data.payment]
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const resetState = () => {
    step.value = DEFAULT_VALUES.step
    folio.value = DEFAULT_VALUES.folio
    balance.value = DEFAULT_VALUES.balance
    pack.value = DEFAULT_VALUES.package
    paymentMethod.value = DEFAULT_VALUES.paymentMethod
  }

  return {
    step,
    folio,
    errorFolio,
    balance,
    package: pack,
    paymentMethod,
    adsSubscription,
    getPackageByID,
    getPackageByExternalID,
    setStep,
    setFolio,
    setErrorFolio,
    setPaymentMethod,
    setPackage,
    setAdsSubscription,
    setPackageById,
    setPackageByExternalID,
    fetchSwitchOrder,
    resetState
  }
})

export default useCheckoutStore
