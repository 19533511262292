import {
  PKCONTILIM_CAMPAIGN,
  NULL_CAMPAIGN,
  BALANCE_CAMPAIGNS,
  MTS_CAMPAIGN,
  SEND_BALANCE_CAMPAIGN,
  REQUEST_BALANCE_CAMPAIGN,
  PACKAGES_CAMPAIGNS,
  DIRECT_PACKAGE_CAMPAIGNS,
  PREVIEW_PACKAGE_CAMPAIGN_PREFIXES,
  PAYMENT_CAMPAIGN_PREFIXES
} from '@/shared/constants/campaigns'
import Page from '@/shared/enums/Page'
import useTokenStore from '@/stores/token'
import type { NavigationGuard } from 'vue-router'
import {
  balanceCampaignFlow,
  mtsCampaignFlow,
  packagesCampaignFlow,
  pkcontilimCampaignFlow,
  directPackageCampaignFlow,
  packagePreviewPrefixCampaignFlow,
  paymentPrefixCampaignFlow
} from '@/router/guards/helpers/campaign'
import useSeigyoStore from '@/stores/seigyo'

const campaignGuard: NavigationGuard = async (to, from, next) => {
  const tokenStore = useTokenStore()
  const seigyoStore = useSeigyoStore()
  const isFirstRender = !from.name

  if (!isFirstRender || tokenStore.campaign === NULL_CAMPAIGN || seigyoStore.isMaintenance) {
    next()
    return
  }

  if (tokenStore.campaign === SEND_BALANCE_CAMPAIGN && !seigyoStore.isMaintenance) {
    next({ name: Page.SendBalance })
    return
  }

  if (tokenStore.campaign === REQUEST_BALANCE_CAMPAIGN) {
    next({ name: Page.RequestBalance })
    return
  }

  try {
    const campaignPrefix = tokenStore.campaign.slice(0, 2)
    const isPackagePreviewPrefixCampaign =
      PREVIEW_PACKAGE_CAMPAIGN_PREFIXES.includes(campaignPrefix)

    if (isPackagePreviewPrefixCampaign && tokenStore.campaign !== PKCONTILIM_CAMPAIGN) {
      await packagePreviewPrefixCampaignFlow(next)
      return
    }

    const isPaymentPrefixCampaign = PAYMENT_CAMPAIGN_PREFIXES.includes(campaignPrefix)

    if (isPaymentPrefixCampaign) {
      await paymentPrefixCampaignFlow(next)
      return
    }

    if (!tokenStore.msisdn) {
      next()
      return
    }

    if (tokenStore.campaign === PKCONTILIM_CAMPAIGN) {
      await pkcontilimCampaignFlow(next)
      return
    }

    if (tokenStore.campaign === MTS_CAMPAIGN) {
      await mtsCampaignFlow(next)
      return
    }

    if (DIRECT_PACKAGE_CAMPAIGNS.includes(tokenStore.campaign)) {
      await directPackageCampaignFlow(next)
      return
    }

    if (PACKAGES_CAMPAIGNS.includes(tokenStore.campaign)) {
      await packagesCampaignFlow(next)
      return
    }

    const isBalanceCampaign = BALANCE_CAMPAIGNS.includes(tokenStore.campaign)

    if (isBalanceCampaign) {
      await balanceCampaignFlow(next)
      return
    }
  } catch (error) {
    //
  }

  next()
}

export default campaignGuard
