import { getCampaignPackage } from '@/api/offer'
import { LOCAL_STORAGE_SKIP_PACKAGES_KEY, MEXICO_CITY_AREA_CODE } from '@/shared/constants/general'
import Page from '@/shared/enums/Page'
import useTokenStore from '@/stores/token'
import useAdsStore from '@/stores/ads'
import type { NavigationGuardNext } from 'vue-router'
import redirectToSanval from '@/shared/utils/redirectToSanval'

export const pkcontilimCampaignFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()
  const isMexicoCityAreaCode = tokenStore.msisdnAreaCode === MEXICO_CITY_AREA_CODE

  if (!isMexicoCityAreaCode) {
    next()
    return
  }

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  const response = await getCampaignPackage(tokenStore.campaign)

  next({
    name: Page.PackageDetails,
    params: {
      externalID: response.data.externalID
    }
  })
}

export const mtsCampaignFlow = async (next: NavigationGuardNext) => {
  const adsStore = useAdsStore()
  const tokenStore = useTokenStore()

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  adsStore.setShowMtsCampaignAdd(true)

  next({ name: Page.Packages })
}

export const directPackageCampaignFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  const response = await getCampaignPackage(tokenStore.campaign)

  next({
    name: Page.PackageDetails,
    params: {
      externalID: response.data.externalID
    }
  })
}

export const packagesCampaignFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  next({ name: Page.Packages })
}

export const balanceCampaignFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchProfileCampaignToken()
  }

  if (tokenStore.isCorpPostpaidProfile) {
    next()
    return
  }

  next({ name: Page.Balance })
}

export const packagePreviewPrefixCampaignFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()

  if (!tokenStore.msisdn) {
    localStorage.setItem(LOCAL_STORAGE_SKIP_PACKAGES_KEY, Page.PackageDetails)
    next({ name: Page.SendBalance })
    return
  }

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  try {
    const response = await getCampaignPackage(tokenStore.campaign)

    next({
      name: Page.PackageDetails,
      params: {
        externalID: response.data.externalID
      }
    })
  } catch (error) {
    next()
  }
}

export const paymentPrefixCampaignFlow = async (next: NavigationGuardNext) => {
  const tokenStore = useTokenStore()

  if (!tokenStore.msisdn) {
    localStorage.setItem(LOCAL_STORAGE_SKIP_PACKAGES_KEY, Page.PackageDetails)
    next({ name: Page.SendBalance })
    return
  }

  if (!tokenStore.isProfiledToken) {
    await tokenStore.fetchBuyPackageToken()
  }

  try {
    const response = await getCampaignPackage(tokenStore.campaign)

    await redirectToSanval(response.data.id)
  } catch (error) {
    next()
  }
}
